<template>
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-auto my-auto">
        <div class="dropdown">
          <button
            class="btn btn-outline-success btn-sm dropdown-toggle text-capitalize"
            type="button"
            id="dropdownStatusFilter"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ statusFilter ? statusFilter : "Status filter" }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownStatusFilter">
            <li
              class="dropdown-item cursor-pointer"
              v-if="statusFilter"
              @click="statusFilter = ''"
            >
              Clear Filter
            </li>

            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'new'"
              v-if="statusFilter == 'new' || !statusFilter"
            >
              New
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'hot'"
              v-if="statusFilter == 'hot' || !statusFilter"
            >
              Hot
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'urgent'"
              v-if="statusFilter == 'urgent' || !statusFilter"
            >
              Urgent
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'warm'"
              v-if="statusFilter == 'warm' || !statusFilter"
            >
              Warm
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'cool'"
              v-if="statusFilter == 'cool' || !statusFilter"
            >
              Cool
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'unsuccessful'"
              v-if="statusFilter == 'unsuccessful' || !statusFilter"
            >
              Unsuccessful
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'booked'"
              v-if="statusFilter == 'booked' || !statusFilter"
            >
              Booked
            </li>

            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'being_dealt_with'"
              v-if="statusFilter == 'being_dealt_with' || !statusFilter"
            >
              Being Dealt With
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'pending_booking'"
              v-if="statusFilter == 'pending_booking' || !statusFilter"
            >
              Pending Booking
            </li>

            <li
              class="dropdown-item cursor-pointer"
              @click="statusFilter = 'booking_related'"
              v-if="statusFilter == 'booking_related' || !statusFilter"
            >
              Booking Related
            </li>
          </ul>

          <button
            class="btn btn-sm btn-outline-success ms-3"
            v-if="statusFilter"
            @click="statusFilter = ''"
          >
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>

      <div class="col-auto my-auto">
        <div class="dropdown">
          <button
            class="btn btn-outline-success btn-sm dropdown-toggle text-capitalize"
            type="button"
            id="dropdownInterestFilter"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ interestFilter ? interestFilter : "Interest filter" }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownInterestFilter">
            <li
              class="dropdown-item cursor-pointer"
              v-if="interestFilter"
              @click="interestFilter = ''"
            >
              Clear Filter
            </li>

            <li
              class="dropdown-item cursor-pointer"
              @click="interestFilter = 'somerset'"
              v-if="interestFilter == 'somerset' || !interestFilter"
            >
              Somerset
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="interestFilter = 'malta'"
              v-if="interestFilter == 'malta' || !interestFilter"
            >
              Malta
            </li>
          </ul>

          <button
            class="btn btn-sm btn-outline-success ms-3"
            v-if="interestFilter"
            @click="interestFilter = ''"
          >
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>

      <div class="col-auto my-auto ms-auto me-5">
        <button
          class="btn btn-sm btn-warning"
          @click="$store.enquiriesSavedView = $route.fullPath"
        >
          Save Search
        </button>
      </div>

      <div class="col-auto my-auto">
        <!-- Date range for call backs -->

        <date-range-picker
          ref="picker"
          :opens="datePickerOptions.opens"
          :locale-data="{
            firstDay: 1,
            format: 'DD-MM-YYYY HH:mm:ss',
          }"
          :minDate="datePickerOptions.minDate"
          :maxDate="datePickerOptions.maxDate"
          :singleDatePicker="datePickerOptions.singleDatePicker"
          :timePicker="datePickerOptions.timePicker"
          :timePicker24Hour="datePickerOptions.timePicker24Hour"
          :showWeekNumbers="datePickerOptions.showWeekNumbers"
          :showDropdowns="datePickerOptions.showDropdowns"
          :autoApply="datePickerOptions.autoApply"
          :ranges="datePickerOptions.ranges"
          v-model="dates"
          @update="updateValues"
          :linkedCalendars="datePickerOptions.linkedCalendars"
          :dateFormat="datePickerOptions.dateFormat"
        >
          <template v-slot:input="picker" style="min-width: 350px">
            {{ picker.startDate | formatDate }} -
            {{ picker.endDate | formatDate }}
          </template>
        </date-range-picker>
      </div>
      <div class="col-auto my-auto">
        <div class="dropdown">
          <button
            class="btn btn-outline-success btn-sm dropdown-toggle text-capitalize"
            type="button"
            id="dropdownCallBackFilter"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ callBackFilter ? callBackFilter : "Call Back Filter" }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownCallBackFilter">
            <li
              class="dropdown-item cursor-pointer"
              v-if="callBackFilter"
              @click="callBackFilter = ''"
            >
              Clear
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="callBackFilter = 'today'"
              v-if="callBackFilter == 'today' || !callBackFilter"
            >
              Today
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="callBackFilter = 'tomorrow'"
              v-if="callBackFilter == 'tomorrow' || !callBackFilter"
            >
              Tomorrow
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="callBackFilter = 'this_week'"
              v-if="callBackFilter == 'this_week' || !callBackFilter"
            >
              This Week
            </li>
          </ul>

          <button
            class="btn btn-outline-success btn-sm ms-3"
            v-if="callBackFilter"
            @click="callBackFilter = ''"
          >
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>
      <div class="col-auto my-auto">
        <div class="dropdown">
          <button
            class="btn btn-outline-success btn-sm dropdown-toggle text-capitalize"
            type="button"
            id="typeFilterDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ typeFilter ? typeFilter : "Type Filter" }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="typeFilterDropdown">
            <li
              class="dropdown-item cursor-pointer"
              v-if="typeFilter"
              @click="typeFilter = ''"
            >
              Clear
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="typeFilter = 'web_form'"
              v-if="typeFilter == 'web_form' || !typeFilter"
            >
              Web Form
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="typeFilter = 'crm'"
              v-if="typeFilter == 'crm' || !typeFilter"
            >
              CRM
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="typeFilter = 'christmas_form_2019'"
              v-if="typeFilter == 'christmas_form_2019' || !typeFilter"
            >
              Christmas Form 2019
            </li>
            <li
              class="dropdown-item cursor-pointer"
              @click="typeFilter = 'unknown'"
              v-if="typeFilter == 'unknown' || !typeFilter"
            >
              Unknown
            </li>
          </ul>

          <button
            class="btn btn-outline-success btn-sm ms-3"
            v-if="typeFilter"
            @click="typeFilter = ''"
          >
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="card border-0 shadow-none" v-if="enquiries">
      <div class="card-body">
        <table class="table sortable">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Call Back <i class="fas fa-sort text-right" role="button" @click="setOrderBy('call_back_date')"></i></th>
              <th scope="col">Status <i class="fas fa-sort text-right" role="button" @click="setOrderBy('status')"></i></th>
              <th scope="col">Type <i class="fas fa-sort text-right" role="button" @click="setOrderBy('type')"></i></th>
              <th scope="col">Enquired <i class="fas fa-sort text-right" role="button" @click="setOrderBy('created_at')"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="e in enquiries.data" :key="e.id">
              <td @click="viewEnquiry(e)">{{ e.client ? e.client.name : "-"}}</td>
              <td @click="viewEnquiry(e)">{{ e.client ? e.client.email : "-" }}</td>
              <td @click="viewEnquiry(e)">
                {{ e.call_back_date | formatCallBackDate }}
              </td>
              <td @click="viewEnquiry(e)" class="text-capitalize">
                {{ e.status | formatStatus }}
              </td>
              <td @click="viewEnquiry(e)" class="text-capitalize">
                {{ e.type | formatType }}
              </td>
              <td @click="viewEnquiry(e)">{{ e.created_at | formatDate }}</td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-4">
          <div class="col-md-4 my-auto">
            <button
              class="btn btn-sm btn-outline-success"
              @click="enquiries.current_page = 1"
              :disabled="enquiries.current_page == enquiries.first_page"
            >
              First Page
            </button>

            <button
              class="btn btn-sm btn-outline-success ms-3"
              @click="enquiries.current_page--"
              :disabled="!enquiries.prev_page_url"
            >
              Previous
            </button>
          </div>
          <div class="col-md-4 my-auto text-center">
            <p class="mb-0">
              Page {{ enquiries.current_page }} of
              {{ enquiries.last_page }}
            </p>
          </div>
          <div class="col-md-4 my-auto text-end">
            <button
              class="btn btn-sm btn-outline-success cursor-pointer me-3"
              @click="enquiries.current_page++"
              :disabled="enquiries.current_page == enquiries.last_page"
            >
              Next
            </button>

            <button
              class="btn btn-sm btn-outline-success cursor-pointer"
              @click="enquiries.current_page = enquiries.last_page"
              :disabled="!enquiries.last_page_url"
            >
              Last Page
            </button>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {
      enquiries: {
        current_page: 1,
      },
      typeFilter: this.$route.query.type ? this.$route.query.type : "",
      statusFilter: this.$route.query.status ? this.$route.query.status : "",
      interestFilter: this.$route.query.interest ? this.$route.query.interest : "",
      callBackFilter: this.$route.query.call_back
        ? this.$route.query.call_back
        : "",
      dates: {
        startDate: this.$route.query.call_back_start
          ? this.$route.query.call_back_start
          : null,
        endDate: this.$route.query.call_back_end
          ? this.$route.query.call_back_end
          : null,
      },
      datePickerOptions: {
        minDate: null,
        maxDate: null,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: true,
        autoApply: true,
        linkedCalendars: true,
        ranges: false,
        // dateFormat: (classes, date) => {
        //   // console.log(classes);
        //   // console.log(date);
        // },
      },
      orderBy: '',
      orderDirection: '',
    };
  },
  watch: {
    "enquiries.current_page": {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: this.enquiries.current_page,
                type: this.typeFilter,
                status: this.statusFilter,
                interest: this.interestFilter,
                call_back: this.callBackFilter,
                call_back_start: this.dates.startDate
                  ? moment(this.dates.startDate)
                  : null,
                call_back_end: this.dates.endDate
                  ? moment(this.dates.endDate)
                  : null,
                  orderBy: this.orderBy,
                  orderDirection: this.orderDirection,
              },
            })
            .catch(() => {});
          this.fetchEnquiries();
        }
      },
    },
    statusFilter: {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                type: this.typeFilter,
                status: this.statusFilter,
                call_back: this.callBackFilter,
                call_back_start: this.dates.startDate
                  ? moment(this.dates.startDate)
                  : null,
                call_back_end: this.dates.endDate
                  ? moment(this.dates.endDate)
                  : null,
                  orderBy: this.orderBy,
                  orderDirection: this.orderDirection,
              },
            })
            .catch(() => {});
          this.fetchEnquiries();
        }
      },
    },
    interestFilter: {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                type: this.typeFilter,
                status: this.statusFilter,
                interest: this.interestFilter,
                call_back: this.callBackFilter,
                call_back_start: this.dates.startDate
                  ? moment(this.dates.startDate)
                  : null,
                call_back_end: this.dates.endDate
                  ? moment(this.dates.endDate)
                  : null,
              },
            })
            .catch(() => {});
          this.fetchEnquiries();
        }
      },
    },
    typeFilter: {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                type: this.typeFilter,
                status: this.statusFilter,
                interest: this.interestFilter,
                call_back: this.callBackFilter,
                call_back_start: this.dates.startDate
                  ? moment(this.dates.startDate)
                  : null,
                call_back_end: this.dates.endDate
                  ? moment(this.dates.endDate)
                  : null,
                  orderBy: this.orderBy,
                  orderDirection: this.orderDirection,
              },
            })
            .catch(() => {});
          this.fetchEnquiries();
        }
      },
    },
    callBackFilter: {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                type: this.typeFilter,
                status: this.statusFilter,
                interest: this.interestFilter,
                call_back: this.callBackFilter,
                call_back_start: "",
                call_back_end: "",
                orderBy: this.orderBy,
                orderDirection: this.orderDirection,
              },
            })
            .catch(() => {});
          this.dates.startDate = null;
          this.dates.endDate = null;
          this.fetchEnquiries();
        }
      },
    },

    "dates.startDate": {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                type: this.typeFilter,
                status: this.statusFilter,
                interest: this.interestFilter,
                call_back: "",
                call_back_start: moment(this.dates.startDate),
                call_back_end: moment(this.dates.endDate),
                orderBy: this.orderBy,
                orderDirection: this.orderDirection,
              },
            })
            .catch(() => {});
          this.fetchEnquiries();
        }
      },
    },

    "dates.endDate": {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                type: this.typeFilter,
                status: this.statusFilter,
                interest: this.interestFilter,
                call_back: "",
                call_back_start: moment(this.dates.startDate),
                call_back_end: moment(this.dates.endDate),
                orderBy: this.orderBy,
                orderDirection: this.orderDirection,
              },
            })
            .catch(() => {});
          this.fetchEnquiries();
        }
      },
    },
  },
  methods: {
    updateValues(dates) {
      console.log(dates);

      var start = moment(dates.startDate).startOf("day");
      var end = moment(dates.endDate).startOf("day");

      this.fetchEnquiries();
    },
    setOrderBy(val) {

      if(this.orderBy == val) {
        //Toggle direction
        if(this.orderDirection == 'asc' || this.orderDirection == '')
        {
          this.orderDirection = 'desc';
        }
        else
        {
          this.orderDirection = 'asc';
        }
      }
      else
      {
        this.orderBy = val;
      }

      this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                type: this.typeFilter,
                status: this.statusFilter,
                call_back: this.callBackFilter,
                call_back_start: "",
                call_back_end: "",
                orderBy: this.orderBy,
                orderDirection: this.orderDirection,
              },
            })
            .catch(() => {});

      this.fetchEnquiries();

    },
    viewEnquiry(enquiry) {
      this.$router.push({
        path: "/clients/" + enquiry.client.id,
        hash: "enquiries",
        query: {
          enquiry: enquiry.id,
        },
      });
    },
    fetchEnquiries() {
      this.$axios(process.env.VUE_APP_API_URL + this.$route.fullPath).then(
        ({ data }) => {
          this.enquiries = data;
        }
      );
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchEnquiries();
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("LLL");
      }
      return "Not Set";
    },
    formatCallBackDate(date) {
      if (date) {
        return moment.utc(date).format("LLL");
      }
      return "Not Set";
    },
    formatType(type) {
      return type.replace(/_/g, " ");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
  components: {
    Loading,
    DateRangePicker,
  },
};
</script>

<style></style>
